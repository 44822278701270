<template>
  <v-row v-scroll="onScroll">
    <!-- header -->
    <mini-header title="Información del punto de venta" :breadcrumbs="breadcrumbs" :dense="true" :hiddenTab="true" :addPadding="true" :scroll="scroll">
      <template v-slot:main>
        <!-- <v-btn v-if="env === 'ax'" color="primary" :to="{ name: 'LogsList', query: { pos: instance.id }, params: $route.params }"><v-icon left>mdi-alert-circle</v-icon>Ver LOGS</v-btn> -->
      </template>
    </mini-header>
    <!-- end header -->
    <VSkeleton v-if="$store.state.branches.retrieveLoader || loadingPos" />
    <template v-else>
      <v-col class="px-0" style="z-index: 0;">
        <div class="d-flex">
          <!-- detail card -->
          <div class="mr-6" style="min-width: 400px;">
            <v-card class="pa-0" width="400" transition="slide-y-transition" min-height="calc(100% - 150px)" flat style="position:fixed!important;">
              <VCardMapDetail @isClick="dialogAXPOS=true" :carouselList="['', instance.photo]" :itemList="cardMapDetail" @handleModal="handleEditConfigPos" :hiddenEdit="true" />
            </v-card>
          </div>
          <!-- detail card -->

          <!-- panels -->
          <div class="pb-5" style="flex:1; min-width: 180px;">
            <!-- statistics -->
            <v-card flat class="d-none">
              <v-card-text class="pa-0">
                <v-row justify="center" no-gutters>
                  <!-- status -->
                  <v-col cols="4" class="text-center px-0 py-10">
                    <span class="font-weight-bold subtitle-2 secondary--text">Estado</span>
                    <v-sheet class="d-flex align-center justify-center transparent mt-8">
                      <div>
                        <v-chip class="font-weight-semibold" color="lightBlue" small label>Activo<v-icon right size="18">mdi-cloud</v-icon></v-chip>
                        <span class="mt-2 d-block fontBody--text body-1">{{editable.created | dateTime}}</span>
                      </div>
                    </v-sheet>
                  </v-col>
                  <!-- end status -->
                  <v-divider vertical />
                  <!-- documents issued -->
                  <v-col cols="4" class="text-center py-10 pa-0">
                    <span class="font-weight-bold subtitle-2 secondary--text mx-auto">Cantidad de DTEs</span>
                    <div class="mt-9">
                      <span class="d-block fontBody--text subtitle-2 font-weight-semibold">13.267</span>
                      <span class="d-block secondary--text subtitle-2 mt-2">Hoy</span>
                    </div>
                  </v-col>
                  <!-- end documents issued -->
                  <v-divider vertical />
                  <!-- documents issued -->
                  <v-col cols="4" class="text-center py-10 px-0">
                    <span class="font-weight-bold subtitle-2 secondary--text mx-auto">Total vendido</span>
                    <div class="mt-9">
                      <span class="d-block fontBody--text subtitle-2 font-weight-semibold">$757.953 <span class="fontDraw--text caption">{{$t('generals.CLP')}}</span></span>
                      <span class="d-block secondary--text subtitle-2 mt-2">Hoy</span>
                    </div>
                  </v-col>
                  <!-- end documents issued -->
                </v-row>
                <!-- show more statistics -->
                <template v-if="showMoreStatistics">
                  <v-divider />
                  <v-row class="ma-0" justify="center" no-gutters>
                    <!-- documents issued -->
                    <v-col cols="4" class="text-center py-10">
                      <span class="font-weight-bold subtitle-2 secondary--text mx-auto">Ticket promedio</span>
                      <v-sheet height="100" min-width="100" class="d-flex align-center justify-center transparent">
                        <div>
                          <span class="d-block mt-6 fontBody--text subtitle-2 font-weight-semibold">$45.990</span>
                          <span class="d-block secondary--text subtitle-2 mt-3">Últimos 7 días</span>
                        </div>
                      </v-sheet>
                    </v-col>
                    <!-- end documents issued -->
                    <v-divider vertical />
                    <!-- average ticket -->
                    <v-col cols="4" class="text-center py-10">
                      <span class="font-weight-bold subtitle-2 secondary--text mx-auto">Cantidad de DTEs</span>
                      <v-sheet height="100" min-width="100" class="d-flex align-center justify-center transparent">
                        <div>
                          <span class="d-block mt-6 fontBody--text subtitle-2 font-weight-semibold">145.736</span>
                          <span class="d-block secondary--text subtitle-2 mt-3">Últimos 7 días</span>
                        </div>
                      </v-sheet>
                    </v-col>
                    <!-- end average ticket -->
                    <v-divider vertical />
                    <!-- total sold -->
                    <v-col cols="4" class="text-center py-10">
                      <span class="font-weight-bold subtitle-2 secondary--text mx-auto">Total vendido</span>
                      <v-sheet height="100" min-width="100" class="d-flex align-center justify-center transparent">
                        <div>
                          <span class="d-block mt-6 fontBody--text subtitle-2 font-weight-semibold">$ 5.305.671<span class="fontDraw--text caption">{{$t('generals.CLP')}}</span></span>
                          <span class="d-block secondary--text subtitle-2 mt-3">Últimos 7 días</span>
                        </div>
                      </v-sheet>
                    </v-col>
                    <!-- end total sold -->
                  </v-row>
                </template>
                <!-- end show more statistics -->
              </v-card-text>
              <v-divider />
              <v-card-actions class="pa-0">
                <v-col class="text-center py-2">
                  <v-btn @click="showMoreStatistics=!showMoreStatistics" outlined :ripple="false">{{ !showMoreStatistics ? 'Ver más estadísticas' : 'Ver menos estadísticas' }} </v-btn>
                </v-col>
              </v-card-actions>
            </v-card>
            <!-- end statistics -->

            <!-- pos -->
            <v-expansion-panels class="mt-5 d-none" v-model="panelPos">
              <v-expansion-panel>
                <v-expansion-panel-header class="px-5 py-3">
                  <v-row no-gutters align="center" justify="space-between">
                    <v-col class="pa-0">
                      <span class="ml-n1 subtitle-2 secondary--text font-weight-bold"><v-icon size="18" left color="secondary">mdi-printer-pos</v-icon>Configuración POS</span>
                    </v-col>
                  </v-row>
                </v-expansion-panel-header>
                <v-expansion-panel-content class="px-5">
                  <v-list class="transparent v-list-form px-0">
                    <v-list-item class="my-2">
                      <v-list-item-title class="subtitle-2">Última señal de vida</v-list-item-title>
                      <v-list-item-subtitle class="pl-1">{{instance.created | dateTime}} <!-- de momento --></v-list-item-subtitle>
                    </v-list-item>
                    <v-list-item>
                      <v-list-item-title class="subtitle-2">Instalado el</v-list-item-title>
                      <v-list-item-subtitle class="pl-1">{{instance.created | dateTime}}</v-list-item-subtitle>
                    </v-list-item>
                    <v-list-item class="my-2">
                      <v-list-item-title class="subtitle-2">Versión de {{env === 'ax' ? 'AX' : 'FC'}}-POS</v-list-item-title>
                      <v-list-item-subtitle class="pl-1">v0.9</v-list-item-subtitle>
                    </v-list-item>
                    <v-list-item>
                      <v-list-item-title class="subtitle-2">Versión del filtro {{env === 'ax' ? 'AX' : 'FC'}}-POS</v-list-item-title>
                      <v-list-item-subtitle class="pl-1">
                        v0.1
                        <v-btn class="ml-2 mt-n1 body-1" color="primary" text :ripple="false">Actualizar<v-icon color="primary" right>mdi-autorenew</v-icon></v-btn>
                      </v-list-item-subtitle>
                    </v-list-item>
                    <v-list-item class="my-2">
                      <v-list-item-title class="subtitle-2">HMAC</v-list-item-title>
                      <v-list-item-subtitle class="pl-1">
                        <v-tooltip color="bgSearch" right transition="scale-transition">
                          <template v-slot:activator="{on}">
                            <v-btn @click="toClipboard(instance.hmac || '')" text color="secondary" v-on="on" :ripple="false" style="justify-content: left;">{{instance.hmac || ''}}</v-btn>
                          </template>
                          <span class="d-block px-3 py-2">Copiar</span>
                        </v-tooltip>
                      </v-list-item-subtitle>
                    </v-list-item>
                    <v-list-item>
                      <v-list-item-title class="subtitle-2">Modo de asignación de {{$t('generals.folios')}}</v-list-item-title>
                      <v-list-item-subtitle class="pl-1">Dinámico</v-list-item-subtitle>
                    </v-list-item>
                    <v-list-item class="my-2">
                      <v-list-item-title class="subtitle-2">Modo de almacenamiento</v-list-item-title>
                      <v-list-item-subtitle class="pl-1">{{instance.store_scheme || '-'}}</v-list-item-subtitle>
                    </v-list-item>
                    <v-list-item class="my-2">
                      <v-list-item-title class="subtitle-2">Copias PDF</v-list-item-title>
                      <v-list-item-subtitle class="pl-1">{{instance.pdf_copies || '-'}}</v-list-item-subtitle>
                    </v-list-item>
                    <v-list-item class="my-2">
                      <v-list-item-title class="subtitle-2">Forma de envío</v-list-item-title>
                      <v-list-item-subtitle class="pl-1">{{instance.sending_way || '-'}}</v-list-item-subtitle>
                    </v-list-item>
                    <v-list-item class="my-2">
                      <v-list-item-title class="subtitle-2">Ruta de almacenamiento XML</v-list-item-title>
                      <v-list-item-subtitle class="pl-1">{{instance.store_path || '-'}}</v-list-item-subtitle>
                    </v-list-item>
                    <v-list-item class="my-2">
                      <v-list-item-title class="subtitle-2">Ruta de almacenamiento PDF</v-list-item-title>
                      <v-list-item-subtitle class="pl-1">{{instance.irs_store_path || '-'}}</v-list-item-subtitle>
                    </v-list-item>
                  </v-list>
                </v-expansion-panel-content>
              </v-expansion-panel>
            </v-expansion-panels>
            <!-- end pos -->

            <!-- folios -->
            <v-expansion-panels v-if="$store.state.auth.account.country === 'CL'" class="mt-5" v-model="panelFolios">
              <v-expansion-panel>
                <v-expansion-panel-header class="px-4 py-3">
                  <v-row no-gutters align="center" justify="space-between">
                    <v-col>
                      <span class="subtitle-2 secondary--text font-weight-bold text-capitalize"><v-icon size="18" left color="secondary">mdi-file-document</v-icon>{{$t('generals.folios')}}</span>
                    </v-col>
                  </v-row>
                </v-expansion-panel-header>
                <v-expansion-panel-content class="px-0">
                  <v-divider />
                  <v-empty-state class="mt-n2 pb-2" v-if="!currentList.length" type="documents" id="documentos" customClass="mt-n5" :isFree="true" :title="`Aún no existen ${$t('generals.folios')}`" :height="199" />
                  <v-sheet class="text-center pa-0 mt-n1" rounded v-else>
                    <v-tabs class="mt-n1" color="primary" grow height="46" slider-size="3">
                      <v-tab class="body-2 secondary--text basic-tab" @click="activeFolio=true" exact :ripple="false">Activos</v-tab>
                      <v-tab class="body-2 secondary--text basic-tab" @click="activeFolio=false" exact :ripple="false">Usados</v-tab>
                    </v-tabs>
                    <v-data-table class="table-not-rounded" :headers="currentHeader" :items="currentList" hide-default-header disable-sort hide-default-footer>
                      <!-- header -->
                      <template v-slot:header="{ props: { headers } }">
                        <VTableHeaders :headers="headers" :sortable="false" />
                        <v-divider style="min-width: 100%; position: absolute;  margin-top: -6px; margin-left: -5px;" />
                      </template>
                      <!-- end header -->
                    </v-data-table>
                  </v-sheet>
                </v-expansion-panel-content>
              </v-expansion-panel>
            </v-expansion-panels>
            <!-- end folios -->

            <!-- documents -->
            <v-expansion-panels v-model="panelDocuments">
              <v-expansion-panel>
                <v-expansion-panel-header class="px-4 py-3">
                  <v-row no-gutters align="center" justify="space-between">
                    <v-col>
                      <span class="subtitle-2 secondary--text font-weight-bold"><v-icon size="18" left color="secondary">mdi-file-document-multiple</v-icon>Documentos emitidos</span>
                    </v-col>
                  </v-row>
                </v-expansion-panel-header>
                <v-expansion-panel-content class="px-0">
                  <v-divider />
                  <documents-list :showPagination="false" />
                  <template v-if="count && !loading">
                    <v-divider />
                    <v-row class="px-4 py-4" align="center">
                      <v-btn class="ml-5 body-1" :to="{name: 'DocumentsList', params: currentParams, query: { cpos_id: $route.params.id } }" :ripple="false" text color="primary">Ver todos los documentos</v-btn>
                    </v-row>
                  </template>
                </v-expansion-panel-content>
              </v-expansion-panel>
            </v-expansion-panels>
            <!-- end documents -->
          </div>
          <!-- end panels -->
        </div>
      </v-col>
    </template>
    <!-- dialog edit config Pos -->
    <v-dialog v-model="dialogConfigPos" width="700" persistent no-click-animation scrollable overlay-color="overlay">
      <v-card>
        <v-card-title class="pa-0">
          <v-toolbar flat height="46" class="transparent">
            <v-toolbar-title class="text--secondary subtitle-1 font-weight-medium">Editar configuración POS</v-toolbar-title>
            <v-spacer />
            <v-toolbar-items>
              <v-row align="center" justify="center" class="mr-3">
                <v-btn @click="dialogConfigPos=false" icon small><v-icon size="24">mdi-close</v-icon></v-btn>
              </v-row>
            </v-toolbar-items>
          </v-toolbar>
        </v-card-title>
        <v-divider />
        <v-card-text class="pa-0">
          <v-list class="v-list-form py-6 transparent">
            <v-list-item class="py-1">
              <v-list-item-title>Modo de asignación de {{$t('generals.folios')}}</v-list-item-title>
              <v-list-item-subtitle>
                <v-radio-group class="mx-0 mt-n4" v-model="row" row absolute style="position:absolute" dense hide-details>
                  <v-radio label="Lineal" value="radio-1"></v-radio>
                  <v-radio label="Dinámico" value="radio-2"></v-radio>
                </v-radio-group>
              </v-list-item-subtitle>
            </v-list-item>
            <v-list-item class="py-1">
              <v-list-item-title>Modo de almacenamiento</v-list-item-title>
              <v-list-item-subtitle>
                <v-radio-group class="mx-0 mt-n4" v-model="$v.editable.store_scheme.$mode" row absolute style="position:absolute" dense hide-details>
                  <v-radio label="Base de datos" value="radio-1"></v-radio>
                  <v-radio label="Directorio" value="radio-2"></v-radio>
                </v-radio-group>
              </v-list-item-subtitle>
            </v-list-item>
            <v-list-item class="py-1">
              <v-list-item-title>Copias PDF</v-list-item-title>
              <v-list-item-subtitle>
                <v-col cols="8">
                  <v-select v-model="$v.editable.pdf_copies.$model" :error="$v.editable.pdf_copies.$error" :items="pdfcopiesList" item-text="name" item-value="id" single-line hide-details dense height="30" outlined />
                </v-col>
              </v-list-item-subtitle>
            </v-list-item>
            <v-list-item class="mt-2">
              <v-list-item-title>Forma de envío</v-list-item-title>
              <v-list-item-subtitle class="py-1">
                <v-radio-group class="mx-0 mt-n4" v-model="editable.sending_way" row absolute style="position:absolute" dense hide-details>
                  <v-radio label="XML" value="radio-1"></v-radio>
                  <v-radio label="ZIP" value="radio-2"></v-radio>
                </v-radio-group>
                <!-- <v-col cols="8">
                  <v-select v-model="$v.editable.sending_way.$model" :error="$v.editable.sending_way.$error" :items="sendingWayList" item-text="name" item-value="id" single-line hide-details dense height="30" outlined />
                </v-col> -->
              </v-list-item-subtitle>
            </v-list-item>
              <v-list-item>
            <v-list-item-title>Ruta de almacenamiento XML</v-list-item-title>
              <v-list-item-subtitle class="py-1">
                <v-text-field v-model.trim="$v.editable.store_path.$model" :error="$v.editable.store_path.$error" outlined required single-line dense maxlength="64" hide-details />
              </v-list-item-subtitle>
            </v-list-item>
            <v-list-item>
              <v-list-item-title>Ruta de almacenamiento PDF</v-list-item-title>
              <v-list-item-subtitle class="py-1">
                <v-text-field v-model.trim="$v.editable.irs_store_path.$model" :error="$v.editable.irs_store_path.$error" outlined required single-line dense maxlength="64" hide-details />
              </v-list-item-subtitle>
            </v-list-item>
          </v-list>
        </v-card-text>
        <v-divider />
        <v-card-actions class="px-5 py-2">
          <v-spacer/>
          <v-btn outlined @click="dialogConfigPos=false">Cancelar</v-btn>
          <v-btn color="primary" @click="setConfigPos">Guardar</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <!-- end dialog edit config Pos -->

    <!-- dialog AX-POS -->
    <v-dialog v-model="dialogAXPOS" width="480" persistent no-click-animation scrollable overlay-color="overlay">
      <v-card>
        <v-card-title class="pa-0">
          <v-toolbar flat height="46" class="transparent">
            <v-toolbar-title class="px-5 subtitle-2 fontBody--text font-weight-semibold">AX-PoS</v-toolbar-title>
            <v-spacer />
            <v-toolbar-items>
              <v-row align="center" justify="center" class="mr-3">
                <v-btn class="ml-3" @click="dialogAXPOS=false" icon small retain-focus-on-click><v-icon color="fontBody" size="20">mdi-close</v-icon></v-btn>
              </v-row>
            </v-toolbar-items>
          </v-toolbar>
        </v-card-title>
        <v-divider />
        <v-card-text class="pt-0 pb-2">
          <v-list class="v-list-form-2 transparent">
            <v-list-item>
              <v-list-item-title>MAC Address</v-list-item-title>
              <v-list-item-subtitle>
                <span v-if="axPosDetail?.mac_address">{{ axPosDetail?.mac_address }}</span>
                <span class="body-2 text--disabled font-italic" v-else>Sin especificar</span>
              </v-list-item-subtitle>
            </v-list-item>
            <v-list-item class="py-1">
              <v-list-item-title>Estado</v-list-item-title>
              <v-list-item-subtitle class="py-1">
                <span v-if="axPosDetail?.mac_address">{{ axPosDetail?.active ? 'Activo' : 'Inactivo'}}</span>
              </v-list-item-subtitle>
            </v-list-item>
            <v-list-item>
              <v-list-item-title>Fecha de creación</v-list-item-title>
              <v-list-item-subtitle class="py-1">{{ axPosDetail?.created | dateTimeSecond}}</v-list-item-subtitle>
            </v-list-item>
            <v-list-item class="py-1">
              <v-list-item-title>Asociar configuración</v-list-item-title>
              <v-list-item-subtitle class="py-1">
                <v-autocomplete v-model="newConfig" :items="configList" item-value="id" item-text="name" :menu-props="{ overflowY: true, transition: 'slide-y-transition' }" height="30" single-line outlined dense hide-details>
                  <template v-slot:item="data">
                    <v-list-item-title class="font-weight-regular">{{data.item.name}}</v-list-item-title>
                  </template>
                </v-autocomplete>
              </v-list-item-subtitle>
            </v-list-item>
            <v-list-item>
              <v-list-item-title>Versión de software</v-list-item-title>
              <v-list-item-subtitle class="py-1">
                <v-text-field v-model="swVersion" outlined required single-line dense maxlength="100" hide-details />
              </v-list-item-subtitle>
            </v-list-item>
            <v-list-item class="py-1">
              <v-list-item-title>Certificado</v-list-item-title>
              <v-list-item-subtitle class="py-1">
                <v-autocomplete v-model="certificate" :items="certificatesList" item-value="digest" item-text="name" :menu-props="{ overflowY: true, transition: 'slide-y-transition' }" height="30" single-line outlined dense hide-details>
                  <template v-slot:item="data">
                    <v-list-item-title class="font-weight-regular">
                      <span class="d-block">{{data.item.date_start | date}} - {{data.item.date_end | date}}</span>
                    </v-list-item-title>
                  </template>
                  <template v-slot:selection="data">
                    <v-list-item-title class="font-weight-regular">
                      <span class="d-block">{{data.item.date_start | date}} - {{data.item.date_end | date}}</span>
                    </v-list-item-title>
                  </template>
                </v-autocomplete>
              </v-list-item-subtitle>
            </v-list-item>
          </v-list>
          <span class="subtitle-2 fontBody--text font-weight-medium">Listado de POS</span>
          <v-col class="px-0">
            <v-card class="px-0" flat outlined>
              <v-simple-table class="px-0 no-hover table-not-rounded" dense>
              <template v-slot:default>
                <thead>
                  <tr>
                    <th class="text-left secondary--text font-weight-medium" style="min-width: 180px;">POS</th>
                    <th class="text-left secondary--text font-weight-medium" style="min-width: 120px;">ID</th>
                    <th class="text-left secondary--text font-weight-medium" style="min-width: 70px;">Estado</th>
                  </tr>
                  <v-divider style="position: absolute;  margin-top: -10px; min-width: 100%; margin-left: -1px" />
                </thead>
                <tbody>
                  <tr v-for="item in axPOSList" :key="item.id">
                    <td class="body-2">
                      <span class="d-inline-block text-truncate ml-n1" style="max-width: 99%;" v-if="item.name">{{item.name}}</span>
                      <span class="body-2 text--disabled font-italic" v-else>Sin especificar</span>
                    </td>
                    <td class="text-left body-2" style="min-width: 180px;">
                      <span class="d-inline-block text-truncate" style="max-width: 99%;">{{item.cpos_id}}</span>
                    </td>
                    <td class="text-left">
                      <v-chip v-if="item" :color="item.active ? 'lightBlue' : ''" small label>{{ item.active ? 'Activo' : 'Inactivo' }}<v-icon right size="18">mdi-cloud{{ item.active ? '' : '-off' }}</v-icon></v-chip>
                    </td>
                  </tr>
                </tbody>
              </template>
            </v-simple-table>
            </v-card>
          </v-col>
        </v-card-text>
        <v-divider />
        <v-card-actions class="px-5 py-2">
          <v-spacer/>
          <v-btn outlined @click="dialogAXPOS=false">Cancelar</v-btn>
          <v-btn color="primary" :loading="loadingAssignConfig" @click="assignConf">Guardar</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <!-- end dialog AX-POS -->
  </v-row>
</template>
<script>
import { mapState } from 'vuex'
import VEmptyState from '@/components/commons/VEmptyState'
import MiniHeader from '@/components/commons/MiniHeader'
import { required, maxLength } from 'vuelidate/lib/validators'
import DocumentsList from '@/modules/documents/components/DocumentsList'
import ListViewMixin from '@/mixins/ListViewMixin'
import GenericViewMixin from '@/mixins/GenericViewMixin'
import ChartDoughnut from '@/components/charts/ChartDoughnut'
import ChartLine from '@/components/charts/ChartLine'
import VCardMapDetail from "@/modules/pos/pos/components/complements/VCardMapDetail"
import VTableHeaders from '@/components/VTable/VTableHeaders'
import VSkeleton from '@/modules/pos/pos/components/complements/VSkeletonPos'
import SkeletonList from '@/components/commons/skeletonLoader/SkeletonList'

export default {
  mixins: [
    GenericViewMixin, ListViewMixin
  ],
  components: {
    VEmptyState,
    MiniHeader,
    DocumentsList,
    ChartDoughnut,
    ChartLine,
    VCardMapDetail,
    VTableHeaders,
    VSkeleton,
    SkeletonList
  },
  data: () => ({
    swVersion: null,
    certificate: null,
    scroll: 0,
    loadingPos: false,
    backupEditableBasicInfoPos: {},
    isSaveDisabled: false,
    showMoreStatistics: false,
    activeFolio: true,
    panelFolios: false,
    row: 'radio-1',
    show: false,
    key: 'dsa#$%^dr8485jfs6dasuydsadr8485jf$%s6das&*-@#$%^&uy',
    // example
    chartLineData: {
      datasets: [{
        data: [346, 254, 435, 567, 234, 344, 767, 576, 786, 712, 543, 456],
        label: 'current',
        backgroundColor: '#304FFE',
        percent_change: [-13.88, 8.7, 10.93, 1.58, 3.72, 9.38, 20.78, -18.08, 2.93, 5.13, -3.37, 6.64]
      }],
      labels: ['2021-05-01', '2021-06-01', '2021-07-01', '2021-08-01', '2021-09-01', '2021-10-01', '2021-11-01', '2021-12-01', '2022-01-01', '2022-02-01', '2022-03-01', '2022-04-01']
    },
    // end expample
    posImages: ['', ''],
    panelPos: false,
    panelDocuments: 0,
    loading: false,
    dialogInfoPos: false,
    dialogConfigPos: false,
    editableBasicInfoPos: {
      name: '',
      internal_id: ''
    },
    editable: {
      store_scheme: 1,
      pdf_copies: 1,
      store_path: '',
      sending_way: '',
      irs_store_path: '',
      id: 'pos_oiruehr7ywhfrdsds'
    },
    pdfcopiesList: ['1', '2', '3', '4', '5'],
    sendingWayList: ['xml', 'txt'],
    statusBreadcrumbs: [
      {
        text: 'mdi-store',
        disabled: false,
        type: 'icon'
      },
      {
        text: 'Tienda',
        disabled: false
      },
      {
        text: 'Información del documento',
        disabled: true,
        type: 'name'
      }
    ],
    posBreadcrumbs: [
      {
        text: 'mdi-printer-pos',
        disabled: false,
        type: 'icon'
      },
      {
        text: 'Pos',
        disabled: false
      },
      {
        text: 'Información del documento',
        disabled: true,
        type: 'name'
      }
    ],
    activeFolioList: [
      {
        id: 'pos_dsadr8485jfs6dasuyr1',
        documentTypes: 'Boleta electrónica',
        start: '2',
        end: '10',
        actualFolio: '3',
        restantes: '8',
        asignado: '09 may. 2022 12:31'
      }
    ],
    activeHeaders: [
      { text: 'Tipo de documento', value: 'documentTypes', class: 'font-weight-bold', sortable: false },
      { text: 'Desde', value: 'start', align: 'end', sortable: false },
      { text: 'Hasta', value: 'end', align: 'end', sortable: false },
      { text: 'Folio actual', value: 'actualFolio', align: 'end', sortable: false },
      { text: 'Restantes', value: 'restantes', align: 'end', sortable: false },
      { text: 'Asignado el', value: 'asignado', align: 'end', sortable: false }
    ],
    usedFolioList: [
      {
        id: 'pos_dsadr8485jfs6dasuyr1',
        documentTypes: 'Boleta electrónica',
        start: '2',
        end: '10',
        asignado: '09 may. 2022 12:31'
      },
      {
        id: 'pos_dsadr8485jfs6dasuyr2',
        documentTypes: 'Boleta electrónica',
        start: '2',
        end: '10',
        asignado: '09 may. 2022 12:31'
      },
      {
        id: 'pos_dsadr8485jfs6dasuyr3',
        documentTypes: 'Boleta electrónica',
        start: '2',
        end: '10',
        asignado: '09 may. 2022 12:31'
      }
    ],
    usedHeaders: [
      { text: 'Tipo de documento', value: 'documentTypes', class: 'font-weight-bold', sortable: false },
      { text: 'Desde', value: 'start', align: 'end', sortable: false },
      { text: 'Hasta', value: 'end', align: 'end', sortable: false },
      { text: 'Asignado el', value: 'asignado', align: 'end', sortable: false }
    ],
    env: process.env?.VUE_APP_MODE ?? 'ax',
    breadcrumbs: {
      main: 'Tiendas',
      children: [
        {
          text: ''
        },
        {
          text1: ''
        }
      ]
    },
    instance: {},
    currentBranch: {},
    dialogAXPOS: false,
    axPOSList: [],
    configList: [],
    loadingAssignConfig: false,
    newConfig: '',
    axPosDetail: {},
    certificatesList: []
   }),
  computed: {
    ...mapState({
      count: state => state.documents.documentsCount,
      posSequencesList: state => state.pos.cposSequencesList,
      documentsList: state => state.documents.documentsList
    }),
    currentHeader () {
      return this.activeFolio ? this.activeHeaders : this.usedHeaders
    },
    currentList () {
      return this.activeFolio ? this.activeFolioList : this.usedFolioList
    },
    cardMapDetail () {
      return [
        {
          title: 'Nombre',
          value: this.instance?.name ?? '-'
        },
        {
          title: 'Tienda',
          value: this.currentBranch?.name ?? '-',
          id: this.currentBranch.branch_id
        },
        {
          title: 'ID',
          value: this.instance?.cpos_id ?? '-',
          isTooltip: true
        },
        {
          title: 'ID AX-PoS',
          value: this.instance?.pos_id ?? null,
          isBtn: true
        }
      ]
    }
  },
  watch: {
    panelDocuments () {
      this.getList()
    },
    panelFolios (val) {
      this.activeHeaders[3].text = this.$t(`generals.${this.activeHeaders[3].text}`)
      // if (val === 0) this.getPosSequencesList()
    },
    dialogAXPOS (val) {
      if (val) {
        this.$store.dispatch('pos/RETRIEVE', {
          resource: 'pos-app/pos',
          id: this.instance.pos_id
        })
        .then((resp) => {
          this.axPosDetail = resp.data
          this.certificate = resp.data.cert_id
          this.newConfig = resp.data.config_name
          this.swVersion = resp.data.sw_version
        })
        this.$store.dispatch('pos/LIST', {
          resource: 'pos-app/configurations'
        })
        .then((resp) => {
          this.configList = resp.data
        })
        this.$store.dispatch('pos/LIST', {
          resource: `pos-app/pos/${this.instance.pos_id}/cpos`
        })
        .then((resp) => {
          this.axPOSList = resp.data
        })
        this.$store.dispatch('irs/LIST', {
          resource: `certs`
        })
        .then((resp) => {
          this.certificatesList = resp.data
        })
      } else {
        this.newConfig = ''
        this.swVersion = ''
        this.certificate = ''
        this.axPosDetail = {}
        this.certificatesList = []
      }
    }
  },
  async created () {
    this.loadingPos = true
    await this.$store.dispatch('pos/RETRIEVE', {
      resource: `pos-app/cpos/${this.$route.params.id}`
    })
    .then((resp) => {
      this.instance = resp.data
      this.getList()
    })
    this.loadingPos = false
    await this.$store.dispatch('branches/LIST', {
      resource: `pos-app/branches/${this.instance.branch_id}`
    })
    .then((resp) => {
      this.currentBranch = resp.data
    })
    this.breadcrumbs.children[0].text = `Información de la tienda ${this.currentBranch.branch_id}`
    this.breadcrumbs.children[0].route = { name: 'BranchRetrieveUpdate', params: { id: this.currentBranch.branch_id, ...this.currentParams } }
    this.breadcrumbs.children[1].text1 = `Información del Pos ${this.instance.name}`
  },
  methods: {
    assignConf () {
      this.loadingAssignConfig = true
      this.$store.dispatch('pos/UPDATE', {
        resource: 'pos-app/pos',
        id: this.instance.pos_id,
        payload: {
          config_name: this.newConfig,
          cert_id: this.certificate,
          sw_version: this.swVersion
        }
      })
      .then(() => {
        this.$dialog.message.info(`La configuración ${this.newConfig} ha sido agregada al AX-PoS`)
      })
      .catch((error) => {
        this.$dialog.message.error(error.response.data.message)
      })
      .finally(() => {
        this.loadingAssignConfig = false
        this.dialogAXPOS = false
        this.newConfig = ''
      })
    },
    onScroll (e) {
      this.scroll = e.target?.scrollingElement?.scrollTop ?? 0
    },
    async getList () {
      // if (!this.documentsList.length) {
        this.loading = true
        await this.$store.dispatch('documents/LIST', {
          resource: 'documents',
          query: {
            page_size: 6,
            cpos_id: this.instance.cpos_id
          }
        })
        this.loading = false
      // }
    },
    getPosSequencesList () {
      this.$store.dispatch('pos/LIST', {
        resource: 'pos/sequences',
        query: {
          pos: this.instance.id
        }
      })
    },
    setBasicInfoPos () {
      this.$v.editableBasicInfoPos.$touch()
      if (this.$v.editableBasicInfoPos.$invalid) {
        return false
      }
      const payload = {
        name: this.editableBasicInfoPos.name,
        internal_id: this.editableBasicInfoPos.internal_id
      }

      this.updatePos(payload)
    },
    setConfigPos () {
      this.$v.editable.$touch()
      if (this.$v.editable.$invalid) {
        return false
      }
    },
    updatePos (payload = {}) {
      this.$store.dispatch('pos/UPDATE', {
        resource: 'pos',
        id: this.$route.params.id,
        payload: payload
      })
      .then(({data}) => {
        this.editable = data
        this.$dialog.message.info('Los datos del Pos fueron actualizados correctamente')
      })
      .catch((error) => {
        this.$dialog.message.error(error.response.data.message)
      })
      .finally(() => {
        this.dialogInfoPos = false
      })
    },
    handleEditConfigPos () {
      this.dialogConfigPos = true
      this.editable = {...this.instance}
      const config = ['store_scheme', 'pdf_copies', 'store_path', 'sending_way', 'irs_store_path']
      Object.keys(this.editable).forEach(key => {
        if (!config.includes(key)) {
          delete this.editable[key]
        }
      })
    },
    handleEditBasicInfoPos () {
      this.dialogInfoPos = true
      this.editableBasicInfoPos = {
        name: this.instance.name,
        internal_id: this.instance.internal_id ?? 'store_34'
      }
      this.backupEditableBasicInfoPos = this.editableBasicInfoPos
    }
  },
  validations () {
     const editableBasicInfoPos = {
      internal_id: { required },
      name: { required }
    }
    const editable = {
      store_scheme: { required, maxLength: maxLength(100) },
      pdf_copies: { required },
      store_path: { required, maxLength: maxLength(64) },
      sending_way: { required, maxLength: maxLength(254) },
      irs_store_path: { required, maxLength: maxLength(16) }
    }
    return {
      editableBasicInfoPos,
      editable
    }
  }
}
</script>